import * as React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { PcNavWrapper } from '../../styles/layout.style';
import { authStore } from '../../store';

interface IPcNavProps {
  mouseEnterHandler?: any;
  mouseLeaveHandler?: any;
  navClickHandler: any;
}

const PcNav: React.FC<IPcNavProps> = (props) => {
  const history = useHistory();

  const onLogout = () => {
    authStore.logOut();
    localStorage.removeItem('token'); // 로컬 스토리지에서 JWT 삭제
    history.push('/'); // 로그아웃 후 메인 페이지로 이동
  };

  return (
    <PcNavWrapper>
      <div className="pcNav" onMouseEnter={props.mouseEnterHandler} onMouseLeave={props.mouseLeaveHandler}>
        {authStore.data ? (
          <>
            <div>
              <NavLink className="menu" to="/action" onClick={props.navClickHandler}>
                행동특성 및 종합의견
              </NavLink>
              <NavLink className="menu" to="/subject" onClick={props.navClickHandler}>
                세부능력 및 특기사항
              </NavLink>
              <NavLink className="menu" to="/creative" onClick={props.navClickHandler}>
                창의적 체험활동
              </NavLink>
            </div>
            <NavLink to="/" onClick={() => { onLogout(); window.scrollTo(0, 0); }}>
              로그아웃
            </NavLink>
          </>
        ) : (
            <>
              <div style={{ }}></div>
              <NavLink to="/signin" onClick={props.navClickHandler}>
                로그인
              </NavLink>
            </>
        )}
      </div>
    </PcNavWrapper>
  );
};

export default observer(PcNav);