import * as React from 'react'
import { FooterWrapper } from '../styles/layout.style'
import entbe_logo from '../assets/image/entbe_logo.png'

export interface IFooterProps {}

export default class footer extends React.Component<IFooterProps> {
  public render() {
    return (
      <FooterWrapper>
        <div className="wrap fWrap">
          <img src={entbe_logo} alt="앙트비 로고" />
          <ul>
            <li style={{fontSize:16, marginTop:12, marginBottom:12, fontWeight:500}}>주식회사 앙트비</li>
            <li>사업자번호 : 477-88-01610</li>
            <li>대표자 : 김지민, 황동규</li>
            <li>대표전화 : 010-5360-5938, 010-5150-2172</li>
            <li style={{marginTop: 12}}>Copyright © 2024 Entbe. All Rights Reserved.</li>
          </ul>
          
          {/* <div className="sns">
            <a rel="noreferrer" href="https://www.facebook.com/purpleseed1/" target="_blank">
              <img src={facebook_ico} alt="페이스북" />
            </a>
            <a rel="noreferrer" href="https://www.instagram.com/purpleseed1/" target="_blank">
              <img src={insta_ico} alt="인스타그램" />
            </a>
            <a rel="noreferrer" href="https://blog.naver.com/purpleseed1" target="_blank">
              <img src={blog_ico} alt="블로그" />
            </a>
            <a rel="noreferrer" href="https://www.youtube.com/channel/UCXgt0Tn6zS0yT8EtixJl4ww" target="_blank">
              <img src={youtube_ico} alt="유튜브" />
            </a>
          </div> */}
        </div>
      </FooterWrapper>
    )
  }
}
