import * as React from 'react'
import { Tabs } from 'antd'
import queryString from 'query-string'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import ActionTab from './ActionTab'
import CreativeTab from './CreativeTab'
import SubjectTab from './SubjectTab'
import { HomeWrapper } from '../../styles/beta.style'


export interface IBetaProps { }

const { TabPane } = Tabs

const Beta: React.FC<IBetaProps> = (props: IBetaProps) => {
  const history = useHistory()
  const { search } = useLocation()
  const query = queryString.parse(search)
    return (
      <HomeWrapper>
        <Tabs
          defaultActiveKey={query.tab ? query.tab.toString() : 'company'}
          activeKey={query.tab ? query.tab.toString() : 'company'}
          onTabClick={(tab) => {
            history.push({
              search: `?tab=${tab.toString()}`,
            })
          }}
        >
          <TabPane tab="행동특성 및 종합의견" key="action">
            <ActionTab />
          </TabPane>
          {/* <TabPane tab="세부능력 및 특기사항" key="subject">
            <SubjectTab />
          </TabPane>
          <TabPane tab="창의적 체험활동 사항" key="creative">
            <CreativeTab />
          </TabPane> */}
        </Tabs>
      </HomeWrapper>
    )
  }
export default Beta
