import { observable, action, makeObservable, runInAction } from "mobx";

// 사용자 데이터의 타입 정의
interface UserData {
  id: string;
  role: string;
  email: string;
  username: string;
  password: string;
  school: string;
}

// authStore의 정의
class AuthStore {
  isLoggingIn = false;
  data: UserData | null = null;

  constructor() {
    makeObservable(this, {
      isLoggingIn: observable,
      data: observable,
      logIn: action,
      logOut: action,
      initialize: action,
    });
    this.initialize();
  }

  initialize() {
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      this.data = JSON.parse(storedData);
    }
  }

  logIn(data: UserData) {
    this.isLoggingIn = true;
    setTimeout(action(() => {
      this.data = data;
      this.isLoggingIn = false;
      localStorage.setItem('userData', JSON.stringify(data)); // 사용자 데이터 저장
    }), 0);
  }

  logOut() {
    runInAction(() => {
      this.data = null;
    });
    localStorage.removeItem('userData'); // 사용자 데이터 제거
  }
}

// postStore의 정의
class PostStore {
  data: any[] = [];

  constructor() {
    makeObservable(this, {
      data: observable,
      addPost: action,
    });
  }

  addPost(data: any) {
    this.data.push(data);
  }
}

const authStore = new AuthStore();
const postStore = new PostStore();

export { authStore, postStore };