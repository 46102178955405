import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { callChatGPTCreative, getRecordSetByUserId, getRecordBySetId, createRecord, createRecordSet, updateRecord, deleteRecord } from '../../api';
import { ActionTable, HomeWrapper, MenuWrapper, MenuText, Img, MenuDesc, ToolWrapper, ToolSubWrapper, BorderButton, SolidButton } from '../../styles/beta.style';
import { useHistory } from 'react-router-dom';
import { authStore } from '../../store';

import { FaRegTrashAlt } from "react-icons/fa";


export interface ICreativeProps { }

interface StudentData {
  recordId: string;
  name: string;
  info1: string; //성향
  info2: string; //행동특성
  info3: string; //특이사항
  result: string;
  toggle: boolean;
  loading: boolean;
}

interface SetData {
  id: string;
  setId: string;
  setcategory: string;
  setname: string;
}

// 특정 사용자 ID로 레코드 세트 조회
const fetchRecordSetByUserId = async (userId:any) => {
  try {
    const response = await getRecordSetByUserId(userId);
    console.log('Record Set:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching record set:', error);
  }
};

// 특정 세트 ID로 레코드 조회
const fetchRecordBySetId = async (setId:any) => {
  try {
    const response = await getRecordBySetId(setId);
    console.log('Record:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching record:', error);
  }
};



// const studentData = [
//   { id: 1, 이름: '홍길동', 성향: '외향적', 행동특성: '성실하며 책임감이 강함', 특이사항: '교내외 다양한 봉사활동', result: '', toggle: false, loading: false },
//   { id: 2, 이름: '김첨지', 성향: '중립적', 행동특성: '착하고 자비로움', 특이사항: '진로와 관련된 대외활동에 적극적', result: '', toggle: false, loading: false },
//   { id: 3, 이름: '점순이', 성향: '내향적', 행동특성: '헌신적이고 애정이 많음', 특이사항: '원만한 교우관계를 만듦', result: '', toggle: false, loading: false },
//   { id: 4, 이름: '양소유', 성향: '외향적', 행동특성: '활발한 성격이며 도전적임', 특이사항: '예체능에 소질이 있음', result: '', toggle: false, loading: false },
//   { id: 5, 이름: '허생원', 성향: '내향적', 행동특성: '자유롭고 유머러스함', 특이사항: '교내 학생회 활동을 활발히 함', result: '', toggle: false, loading: false },
// ];

const Creaitve: React.FC<ICreativeProps> = observer(() => {

  const history = useHistory();

  // const [data, setData] = useState<StudentData[]>([]);
  const [recordSets, setRecordSets] = useState<SetData[]>([]);
  const [activeRecordSets, setActiveRecordSets] = useState();
  const [record, setRecord] = useState<StudentData[]>([]);
  const [isGlobalLoading, setIsGlobalLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (authStore.data?.id) {
        const recordSetsAll = await fetchRecordSetByUserId(authStore.data.id);
        //0: 행발, 1: 세특, 2: 창체
        const recordSets = recordSetsAll.filter((record: any) => record.setcategory === 1);
        console.log("길이", recordSets)
        if (recordSets.length > 0) {
          setRecordSets(recordSets);
          setActiveRecordSets(recordSets[0].setId)
        }
        else {
          if (recordSets.length == 0) {
            createRecordSet(authStore.data.id, 1, "기본")
            history.push('/action')
          }
        }
        
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (activeRecordSets) {
        const records = await fetchRecordBySetId(activeRecordSets);
        setRecord(records);
      }
    };
    fetchData();
    console.log("record", activeRecordSets)
  }, [activeRecordSets])
  
  const onChangeChar = useCallback(
    (index, field, value) => {
      const newData = [...record];
      newData[index] = { ...newData[index], [field]: value };
      setRecord(newData);
      console.log(record)
    },
    [record]
  );

  const onButton = useCallback(
    (index) => {
      setIsGlobalLoading(true); // 비동기 작업 시작 시 전역 로딩 상태 활성화
      // 로딩 시작 전에 loading 상태를 true로 설정
      setRecord(currentData =>
        currentData.map((item, i) =>
          i === index ? { ...item, loading: true } : item
        )
      );
      const newRow = { ...record[index] };
      if (newRow.result === "" && !newRow.toggle) {
        // 새로운 결과를 설정하기 전에 ChatGPT로부터 결과를 받아옵니다.
        // 이 부분은 비동기 처리가 필요합니다.
        const prompt = newRow.info1 + "," + newRow.info2 + "," + newRow.info3;
        callChatGPTCreative(prompt).then(response => {
          // 성공적으로 응답을 받았을 때 처리 로직
          // console.log(response.response.content)
          newRow.result = response.response.content; // 여기에 응답 데이터에 기반한 값을 설정
          newRow.loading = false;
          newRow.toggle = true
          const newData = [...record.slice(0, index), newRow, ...record.slice(index + 1)];
          setRecord(newData);
          console.log('로딩끝')
        }).catch(error => {
          console.error("Error fetching data from ChatGPT:", error);
          newRow.loading = false;
          const newData = [...record.slice(0, index), newRow, ...record.slice(index + 1)];
          setRecord(newData);
          console.log('로딩끝')
          // 오류 처리
        }).finally(() => {
          setIsGlobalLoading(false); // 비동기 작업 완료 시 전역 로딩 상태 비활성화
        });
        // 비동기 호출이기 때문에, 여기서 `setData`를 즉시 호출하면 안 됩니다.
        return; // 비동기 처리가 진행되는 동안 이 함수의 실행을 종료합니다.
        // If the row is already expanded ("TEST" is set), collapse it by clearing the result.
      } else {
        if (newRow.result != "" && !newRow.toggle) {
          //toggle False, Result 값 있을때
          newRow.toggle = true
        } else {
          newRow.toggle = false
        }
        setIsGlobalLoading(false); // 동기적 변경이므로 여기에서도 비활성화
      }
      const newData = [...record.slice(0, index), newRow, ...record.slice(index + 1)];
      setRecord(newData);
    },
    [record]
  );

  const onRegenerateButton = useCallback((index) => {
      setIsGlobalLoading(true);
      setRecord(currentData =>
        currentData.map((item, i) =>
          i === index ? { ...item, loading: true } : item
        )
      );
      const newRow = { ...record[index] };
      //기존 답변 삭제
      newRow.result = "";
      newRow.loading = true;
      newRow.toggle = true
      const newData = [...record.slice(0, index), newRow, ...record.slice(index + 1)];
      setRecord(newData);
      // 새로운 결과를 설정하기 전에 ChatGPT로부터 결과를 받아옵니다.
      // 이 부분은 비동기 처리가 필요합니다.
      const prompt = newRow.info1 + "," + newRow.info2 + "," + newRow.info3;
      callChatGPTCreative(prompt).then(response => {
        // 성공적으로 응답을 받았을 때 처리 로직
        newRow.result = response.response.content; // 여기에 응답 데이터에 기반한 값을 설정
        newRow.loading = false;
        newRow.toggle = true
        const newData = [...record.slice(0, index), newRow, ...record.slice(index + 1)];
        setRecord(newData);
        console.log('로딩끝')
      }).catch(error => {
        console.error("Error fetching data from ChatGPT:", error);
        newRow.loading = false;
        const newData = [...record.slice(0, index), newRow, ...record.slice(index + 1)];
        setRecord(newData);
        console.log('로딩끝')
        // 오류 처리
      }).finally(() => {
        setIsGlobalLoading(false);
      });
      setIsGlobalLoading(false); // 동기적 변경이므로 여기에서도 비활성화
      // 비동기 호출이기 때문에, 여기서 `setData`를 즉시 호출하면 안 됩니다.
      return; // 비동기 처리가 진행되는 동안 이 함수의 실행을 종료합니다.
      // If the row is already expanded ("TEST" is set), collapse it by clearing the result.
    }, [record]
  )

  const onSelectBoxChange = (e:any) => {
    setActiveRecordSets(e.target.value)
  }

  const SelectBox = () => (
    <select onChange={onSelectBoxChange}>
      {recordSets.map((recordSet, index) => (
        <option key={recordSet.id} value={recordSet.id}>{recordSet.setname}</option>
      ))}
    </select>
  );

  const addNewRow = () => {
    const newRow = {
      recordId: '',
      name: '',
      info1: '',
      info2: '',
      info3: '',
      result: '',
      toggle: false,
      loading: false
    };
    setRecord([...record, newRow]);
  };

  const handleSave = async () => {
    for (const records of record) {
       console.log(records)
      if (records.recordId) {
        await updateRecord(records.recordId, activeRecordSets,records.info1, records.info2, records.info3, records.result);
      } else {
        await createRecord(records.info1, records.info2, records.info3, records.name, authStore.data?.id, records.result, activeRecordSets);
      }
    }
    alert('Changes saved successfully');
  };

  const handleDelete = async (index: any, recordId: any) => {
    await deleteRecord(recordId);
    const newData = record.filter((_, i) => i !== index);
    setRecord(newData);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('복사되었습니다.');
    }).catch(err => {
      console.error('Error copying to clipboard: ', err);
    });
  };

  const StudentTable = useMemo(() => {
    const dataWithEmptyRow = record.length === 0 ? [{ recordId: '', name: '', info1: '', info2: '', info3: '', toggle: false, result: '', loading: false }] : record;
    setRecord(dataWithEmptyRow)
    return (
      <>
        {dataWithEmptyRow.map((value, index) => (
          <>
            <tr key={index}>
              <td>{index+1}</td>
              <td><input type="text" placeholder={"홍길동"} value={value.name} onChange={(e) => onChangeChar(index, 'name', e.target.value)} /></td>
              <td><input type="text" placeholder={"자율/동아리/진로"} value={value.info1} onChange={(e) => onChangeChar(index, 'info1', e.target.value)} /></td>
              <td><input type="text" placeholder={"자율/동아리/진로 활동 내용"} value={value.info2} onChange={(e) => onChangeChar(index, 'info2', e.target.value)} /></td>
              <td><input type="text" placeholder={"활동 내 특이사항"} value={value.info3} onChange={(e) => onChangeChar(index, 'info3', e.target.value)} /></td>
              {
                !value.toggle ? (
                  <td>
                    <button
                      onClick={() => onButton(index)}
                      // disabled={isGlobalLoading}
                      style={value.result == "" ? { width: 150, fontSize: 16, backgroundColor: '#FFD965', paddingTop: 5, paddingBottom: 5, paddingLeft: 15, paddingRight: 15, borderWidth: 0, borderRadius: 5 } : { width: 150, fontSize: 16, backgroundColor: '#0F7B40', paddingTop: 5, paddingBottom: 5, paddingLeft: 15, paddingRight: 15, borderWidth: 0, borderRadius: 5, color: '#ffffff' }}>
                      {value.result == "" ? ("생성하기") : ("확인하기")}
                    </button>
                  </td>
                ) : (
                  <td>
                    <button onClick={() => onButton(index)}
                      // disabled={isGlobalLoading}
                      style={{ width: 150, fontSize: 16, backgroundColor: '#535353', paddingTop: 5, paddingBottom: 5, paddingLeft: 15, paddingRight: 15, borderWidth: 0, borderRadius: 5, color: "#ffffff" }}>
                      닫기</button>
                  </td>
                )}
              <td>
                <button
                  onClick={() => handleDelete(index, value.recordId)}
                  style={{ width: 80, fontSize: 24, backgroundColor: '#ffffff', paddingTop: 5, paddingBottom: 5, paddingLeft: 15, paddingRight: 15, borderWidth: 0, borderRadius: 5, color: "#BDBDBD" }}>
                  <FaRegTrashAlt/>
                </button>
              </td>
            </tr>
            {value.loading && (
              <>
                <tr className='description'>
                  <td colSpan={6}> 로딩 중 </td>
                </tr>
              </>
            )}
            {value.toggle && value.result != "" && (
              // toggle 상태가 true일 때만 추가 정보 렌더링
              <>
                <tr className='description'>
                  <td></td>
                  <td colSpan={5} style={{ textAlign: 'left', paddingLeft: 5, fontWeight: 700, }}> 생성결과 </td>
                </tr>
                <tr className='description'>
                  <td></td>
                  <td colSpan={5} style={{ textAlign: 'left', paddingLeft: 5, paddingRight: 50 }}> {value.result} </td>
                </tr>
                <tr className='description'>
                  <td></td>
                  <td>
                    <button onClick={() => copyToClipboard(value.result)}>
                      복사하기
                    </button>
                  </td>
                  <td>
                    <button disabled={isGlobalLoading} style={{ backgroundColor: '#FFD965', color: '#535353' }} onClick={() => onRegenerateButton(index)}>
                      재생성하기
                    </button>
                  </td>
                  <td colSpan={3}></td>
                </tr>
              </>
            )}
          </>
        ))}
      </>
    )
  },
    [record, onChangeChar, onButton]
  );

  

  return (
    <HomeWrapper>
      <MenuWrapper>
        <a onClick={() => history.push('/select')}>
          <Img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b66e0e117a095bc5c8db6a3d44506af1d7cfe124caf391ed4720dfd4bc07f0f2?"
          />
        </a>
        <div>
          <MenuText>💡 창의적 체험 활동</MenuText>
          <MenuDesc>창의적 체험 활동 항목의 특기사항을 100자 이내로 자동 생성합니다.</MenuDesc>
        </div>
      </MenuWrapper>
      <ToolWrapper>
        <ToolSubWrapper>
          <p>구분</p>
          {SelectBox()}
        </ToolSubWrapper>
        <ToolSubWrapper>
          <BorderButton onClick={handleSave}>저장</BorderButton>
          <SolidButton onClick={addNewRow}>추가</SolidButton>
          <p style={{ fontSize: 24 }}>⋮</p>
        </ToolSubWrapper>
      </ToolWrapper>
      <ActionTable>
        <thead>
          <tr>
            <th>번호</th>
            <th>이름</th>
            <th>자율/동아리/진로</th>
            <th>활동 상세</th>
            <th>특기 사항</th>
            <th>결과</th>
            <th>삭제</th>
          </tr>
        </thead>
        <tbody>{StudentTable}</tbody>
      </ActionTable>
      {/* <ButtonWrapper>
        <a>
          <img src={excel} />
          Excel 업로드 기능 준비중
        </a>
      </ButtonWrapper> */}
    </HomeWrapper>
  )

});

export default Creaitve;