import styled from 'styled-components'
import bg from '../assets/image/main_bg.png'

export const SigninWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin : 75px 0 30px;
  padding: 30px 162px;
`
export const Toolbar = styled.div`
  display:flex;
  p {margin: 0 45px; font-size: 16px;}
`

export const InputWrapper = styled.div`
  width: 25%;
  min-width: 250px;
  padding: 20px 0;
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  margin : 0 auto; 
  p {font-size: 16px; margin: 1em 0;}
`

// const BlankBox = (props: any) => {
//   return ( <></> )
// }


export const Wrapper = styled.div`
  display: flex;
  width:100%;
  max-width: 1024px;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: #4d4d4d;
  font-weight: 700;
  margin: 105px auto 30px;
`;

export const MenuWrapper = styled.div`
  align-self: start;
  display: flex;
  gap: 20px;
  justify-content: space-between;
`;

export const Img = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
`;

export const MenuText = styled.div`
  font-family: Pretendard, sans-serif;
`;

export const InputTitle = styled.div`
  font-family: Pretendard, sans-serif;
  margin: 34px 0 0 0;
  @media (max-width: 991px) {
    margin-right: 10px;
  }
`;

export const InputBlank = styled.input`
  border-radius: 5px;
  border-color: rgba(190, 190, 190, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  margin-top: 18px;
  width: 214px;
  height: 33px;
  padding: 5px;
`;

export const SolidButton = styled.button`
  border : none;
  font-family: Pretendard, sans-serif;
  border-radius: 5px;
  background-color: #ffa842;
  margin-top: 50px;
  width: 214px;
  max-width: 100%;
  align-items: center;
  color: #fff;
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  padding: 10px 60px;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
    padding: 0 20px;
  }
`;

export const BorderButton = styled.button`
  color:#4d4d4d;
  font-family: Pretendard, sans-serif;
  border-radius: 5px;
  border-color: rgba(255, 191, 116, 1);
  border-style: solid;
  border-width: 2px;
  background-color: #fff;
  margin-top: 15px;
  width: 214px;
  max-width: 100%;
  align-items: center;
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  padding: 10px 60px;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;