import * as React from 'react';
import { useMemo, useState, useCallback } from 'react';
import { ActionTable, ButtonWrapper } from '../../styles/beta.style';
import Axios from 'axios';
import excel from '../../assets/image/excel.png'

export interface IActionTabProps { }

// export const API = 'http://localhost:3000/api';
// export const URL = 'http://localhost:3000';

export const API = process.env.REACT_APP_API_API;
export const URL = process.env.REACT_APP_URL

const studentData = [
  { id: 1, 이름: '홍길동', 성향: '외향적', 행동특성: '성실하며 책임감이 강함', 특이사항: '교내외 다양한 봉사활동', result: '', toggle: false, loading: false },
  { id: 2, 이름: '김첨지', 성향: '중립적', 행동특성: '착하고 자비로움', 특이사항: '진로와 관련된 대외활동에 적극적', result: '', toggle: false, loading: false },
  { id: 3, 이름: '점순이', 성향: '내향적', 행동특성: '헌신적이고 애정이 많음', 특이사항: '원만한 교우관계를 만듦', result: '', toggle: false, loading: false },
  // { id: 4, 이름: '양소유', 성향: '외향적', 행동특성: '활발한 성격이며 도전적임', 특이사항: '예체능에 소질이 있음', result: '', toggle: false, loading: false },
  // { id: 5, 이름: '허생원', 성향: '내향적', 행동특성: '자유롭고 유머러스함', 특이사항: '교내 학생회 활동을 활발히 함', result: '', toggle: false, loading: false },
];

const postRequest = async (url:string, payload:any) => {
  return await Axios.post(`${API}/${url}`, payload);
};

const getRequest = async (url: string, params: any) => {
  return await Axios.get(`${API}/${url}`, { params });
};

export const callChatGPT = (prompt: string) => {
  const url = 'action';
  // 직접적으로 `prompt`를 `params`로 전달합니다.
  return postRequest(url, { prompt });
};

export default function ActionTab(props: IActionTabProps) {
  const [data, setData] = useState(studentData);
  const [isGlobalLoading, setIsGlobalLoading] = useState(false);

  const onChangeChar = useCallback(
    (index, field, value) => {
      const newData:any = [...data];
      newData[index][field] = value;
      setData(newData);
    },
    [data]
  );

  //행 추가 삭제 함수
  // const onButton = useCallback(
  //   (index) => {
  //     setToggleAdd(!toggleAdd); // 토글 상태를 반전시킵니다.
  //     if (!toggleAdd) {
  //       // 새로운 빈 행을 생성합니다.
  //       const newRow = { id: '' };
  //       // 선택된 인덱스 다음 위치에 새 행을 삽입합니다.
  //       const newData: any = [...data.slice(0, index + 1), newRow, ...data.slice(index + 1)];
  //       setData(newData);
  //     } else {
  //       // 토글이 비활성화되면 마지막 행을 제거합니다.
  //       const newData:any = [...data.slice(0, index+1), ...data.slice(index+2)];
  //       setData(newData);
  //     }
  //   },
  //   [toggleAdd,data]
  // );

  const onButton = useCallback(
    (index) => {
      setIsGlobalLoading(true); // 비동기 작업 시작 시 전역 로딩 상태 활성화
      // 로딩 시작 전에 loading 상태를 true로 설정
      setData(currentData =>
        currentData.map((item, i) =>
          i === index ? { ...item, loading: true } : item
        )
      );
      const newRow = { ...data[index] };
      if (newRow.result === "" && !newRow.toggle) {
        // 새로운 결과를 설정하기 전에 ChatGPT로부터 결과를 받아옵니다.
        // 이 부분은 비동기 처리가 필요합니다.
        const prompt = newRow.성향 + "," + newRow.행동특성 + "," + newRow.특이사항;
        callChatGPT(prompt).then(response => {
          // 성공적으로 응답을 받았을 때 처리 로직
          newRow.result = response.data.response.content; // 여기에 응답 데이터에 기반한 값을 설정
          newRow.loading = false;
          newRow.toggle = true
          const newData = [...data.slice(0, index), newRow, ...data.slice(index + 1)];
          setData(newData);
          console.log('로딩끝')
        }).catch(error => {
          console.error("Error fetching data from ChatGPT:", error);
          newRow.loading = false;
          const newData = [...data.slice(0, index), newRow, ...data.slice(index + 1)];
          setData(newData);
          console.log('로딩끝')
          // 오류 처리
        }).finally(() => {
          setIsGlobalLoading(false); // 비동기 작업 완료 시 전역 로딩 상태 비활성화
        });
        // 비동기 호출이기 때문에, 여기서 `setData`를 즉시 호출하면 안 됩니다.
        return; // 비동기 처리가 진행되는 동안 이 함수의 실행을 종료합니다.
        // If the row is already expanded ("TEST" is set), collapse it by clearing the result.
      } else {
        if (newRow.result != "" && !newRow.toggle) {
          //toggle False, Result 값 있을때
          newRow.toggle = true
        } else {
          newRow.toggle = false 
        }
        setIsGlobalLoading(false); // 동기적 변경이므로 여기에서도 비활성화
      }
      const newData = [...data.slice(0, index), newRow, ...data.slice(index + 1)];
      setData(newData);
    },
    [data]
  );

  const onRegenerateButton = useCallback(
    (index) => {
      setIsGlobalLoading(true);
      setData(currentData =>
        currentData.map((item, i) =>
          i === index ? { ...item, loading: true } : item
        )
      );
      const newRow = { ...data[index] };
      //기존 답변 삭제
      newRow.result = "";
      newRow.loading = true;
      newRow.toggle = true
      const newData = [...data.slice(0, index), newRow, ...data.slice(index + 1)];
      setData(newData);
      // 새로운 결과를 설정하기 전에 ChatGPT로부터 결과를 받아옵니다.
      // 이 부분은 비동기 처리가 필요합니다.
      const prompt = newRow.성향 + "," + newRow.행동특성 + "," + newRow.특이사항;
      callChatGPT(prompt).then(response => {
        // 성공적으로 응답을 받았을 때 처리 로직
        newRow.result = response.data.response.content; // 여기에 응답 데이터에 기반한 값을 설정
        newRow.loading = false;
        newRow.toggle = true
        const newData = [...data.slice(0, index), newRow, ...data.slice(index + 1)];
        setData(newData);
        console.log('로딩끝')
      }).catch(error => {
        console.error("Error fetching data from ChatGPT:", error);
        newRow.loading = false;
        const newData = [...data.slice(0, index), newRow, ...data.slice(index + 1)];
        setData(newData);
        console.log('로딩끝')
        // 오류 처리
      }).finally(() => {
        setIsGlobalLoading(false);
      });
      setIsGlobalLoading(false); // 동기적 변경이므로 여기에서도 비활성화
      // 비동기 호출이기 때문에, 여기서 `setData`를 즉시 호출하면 안 됩니다.
      return; // 비동기 처리가 진행되는 동안 이 함수의 실행을 종료합니다.
      // If the row is already expanded ("TEST" is set), collapse it by clearing the result.
    }, [data]
  )
  

  const StudentTable = useMemo(
    () => (
      <>
        {data.map((value, index) => (
          <>
          <tr key={index}>
            <td>{value.id}</td>
            <td><input type="text" value={value.이름} onChange={(e) => onChangeChar(index, '이름', e.target.value)} /></td>
            <td><input type="text" value={value.성향} onChange={(e) => onChangeChar(index, '성향', e.target.value)} /></td>
            <td><input type="text" value={value.행동특성} onChange={(e) => onChangeChar(index, '행동특성', e.target.value)} /></td>
            <td><input type="text" value={value.특이사항} onChange={(e) => onChangeChar(index, '특이사항', e.target.value)} /></td>
            
              {
              !value.toggle ? (
                <td>
                  <button
                      onClick={() => onButton(index)}
                      // disabled={isGlobalLoading}
                      style={value.result == "" ? { width: 150, fontSize: 16, backgroundColor: '#FFD965', paddingTop: 5, paddingBottom: 5, paddingLeft: 15, paddingRight: 15, borderWidth: 0, borderRadius: 5 } : { width: 150, fontSize: 16, backgroundColor: '#0F7B40', paddingTop: 5, paddingBottom: 5, paddingLeft: 15, paddingRight: 15, borderWidth: 0, borderRadius: 5, color:'#ffffff'}}>
                      {value.result == "" ? ("생성하기"):("확인하기") }
                  </button>
                </td>
              ) : (
                  <td>
                    <button onClick={() => onButton(index)}
                      // disabled={isGlobalLoading}
                      style={{ width: 150, fontSize: 16, backgroundColor: '#535353', paddingTop: 5, paddingBottom: 5, paddingLeft: 15, paddingRight: 15, borderWidth: 0, borderRadius: 5, color:"#ffffff" }}>
                      닫기</button>
                  </td>
              )}
            </tr>
            { value.loading && (
              <>
                <tr className='description'>
                  <td colSpan={6}> 로딩 중 </td>
                </tr>
              </>
            )}
            {value.toggle && value.result!="" && (
              // toggle 상태가 true일 때만 추가 정보 렌더링
              <>
                <tr className='description'>
                  <td></td>
                  <td colSpan={5} style={{ textAlign: 'left', paddingLeft: 5, fontWeight: 700, }}> 생성결과 </td>
                </tr>
                <tr className='description'>
                  <td></td>
                  <td colSpan={5} style={{ textAlign: 'left', paddingLeft: 5, paddingRight: 50 }}> {value.result} </td>
                </tr>
                <tr className='description'>
                  <td></td>
                  <td>
                    <button>
                      복사하기
                    </button>
                  </td>
                  <td>
                    <button disabled={isGlobalLoading} style={{ backgroundColor: '#FFD965', color: '#535353' }} onClick={() => onRegenerateButton(index)}>
                      재생성하기
                    </button>
                  </td>
                  <td colSpan={3}></td>
                </tr>
              </>
            )}
          </>
        ))}
      </>
    ),
    [data, onChangeChar, onButton]
  );

  return (
    <>
      <ActionTable>
        <thead>
          <tr>
            <th></th>
            <th>이름</th>
            <th>성향</th>
            <th>행동특성</th>
            <th>특이사항</th>
            <th>결과</th>
          </tr>
        </thead>
        <tbody>{StudentTable}</tbody>
      </ActionTable>
      <ButtonWrapper>
        <a>
          <img src={excel} />
          Excel 업로드 기능 준비중
        </a>
      </ButtonWrapper>
    </>
  );
}