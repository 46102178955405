import * as React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Home from '../pages/home';
import Beta from '../pages/beta';
import Signin from '../pages/signin';
import Signup from '../pages/signup';
import Action from '../pages/action'
import Subject from '../pages/subject'
import Creative from '../pages/creative'
import logo_wh from '../assets/image/logo_wh.png';
import logo_bk from '../assets/image/logo_bk.png';
import { HeaderWrapper } from '../styles/layout.style';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import MobileNav from './navigation/mobileNav';
import PcNav from './navigation/pcNav';
import { observer } from 'mobx-react-lite';

interface IHeaderProps { }

const Header: React.FC<IHeaderProps> = observer(() => {
  const [scrollStyle, setScrollStyle] = React.useState('');
  const [hoverStyle, setHoverStyle] = React.useState('');
  const [clickStyle, setClickStyle] = React.useState('');
  const [backgroundColor, setBackgroundColor] = React.useState('#000000');
  const [scrollLogo, setScrollLogo] = React.useState(logo_wh);
  const [drawerVisible, setDrawerVisible] = React.useState(false);

  const mobileSubNavHandler = () => {
    setDrawerVisible(false);
    window.scrollTo(0, 0);
  };

  const navClickHandler = () => {
    setClickStyle('clickStyle');
    setHoverStyle('');
    window.scrollTo(0, 0);
  };

  const mouseEnterHandler = () => {
    setHoverStyle('hoverStyle');
    setScrollLogo(logo_bk);
  };

  const scrollStyleHandler = () => {
    if (window.scrollY < 40) {
      setScrollStyle('');
      setScrollLogo(logo_wh);
      setBackgroundColor('#000000');
    } else {
      setScrollStyle('scrollStyle');
      setScrollLogo(logo_bk);
      setBackgroundColor('#ffffff');
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', scrollStyleHandler);
    return () => {
      window.removeEventListener('scroll', scrollStyleHandler);
    };
  }, []);

  return (
    <div>
      <HeaderWrapper>
        <div className={`pc`} style={{ backgroundColor: `#ffffff` }}>
          <Link to="/" onClick={() => window.scrollTo(0, 0)}>
            <img src={logo_bk} alt="advaisor 로고" />
          </Link>
          <PcNav navClickHandler={navClickHandler} />
        </div>
        <div className={`mobile ${scrollStyle}`}>
          <div className="mobileWrap">
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <img src={scrollLogo} alt="advaisor 로고" />
            </Link>
            <div
              className="menuBox"
              onClick={() => {
                setDrawerVisible(true);
              }}
            >
              <FontAwesomeIcon className="menuBar" icon={faBars} />
            </div>
          </div>
        </div>
      </HeaderWrapper>
      <MobileNav drawerVisible={drawerVisible} mobileSubNavHandler={mobileSubNavHandler} />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/beta" component={Beta} />
        <Route exact path="/signin" component={Signin} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/action" component={Action} />
        <Route exact path="/subject" component={Subject} />
        <Route exact path="/creative" component={Creative} />
        <Route path="/" component={Home} />
      </Switch>
    </div>
  );
});

export default Header;
