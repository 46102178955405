import Axios from 'axios'

// export const API = process.env.REACT_APP_API_API;
// export const URL = process.env.REACT_APP_URL

// Axios 인스턴스 생성
const api = Axios.create({
  baseURL: process.env.REACT_APP_API_URL, // API의 기본 URL을 설정
  headers: {
    'Content-Type': 'application/json',
  },
});

// 요청에 JWT 토큰 포함
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 회원가입 요청
export const signup = async (username: string, password: string, email: string, role: string) => {
  const response = await api.post('/signup', { username, password, email, role });
  return response.data;
};

//아이디 중복 검사
export const checkUsername = async (username: string) => {
  const response = await api.post('/check-username', { username });
  return response.data;
};

// 로그인 요청
export const login = async (username: string, password: string) => {
  const response = await api.post('/signin', { username, password });
  if (response.data.token) {
    localStorage.setItem('token', response.data.token); // 토큰 저장
  }
  return response.data;
};

// 로그아웃 요청
export const logout = async () => {
  // 로그아웃 로직을 추가 (예: 서버에서 세션 무효화)
  localStorage.removeItem('token');
};

// 기타 API 요청을 추가할 수 있습니다.
// 예: 회원가입, 데이터 가져오기 등
export const fetchData = async () => {
  const response = await api.get('/data');
  return response.data;
};

//GPT 부르기
export const callChatGPTAction = async (prompt: string) => {
  const response = await api.post('/action', { prompt });
  return response.data;
};

//GPT 부르기
export const callChatGPTSubject = async (prompt: string) => {
  const response = await api.post('/subject', { prompt });
  return response.data;
};

//GPT 부르기
export const callChatGPTCreative = async (prompt: string) => {
  const response = await api.post('/creative', { prompt });
  return response.data;
};

// 특정 사용자 ID로 레코드 세트 조회
export const getRecordSetByUserId = async (userId: any) => {
  const response = await api.get(`/recordSet/${userId}`);
  return response.data;
};

// 특정 세트 ID로 레코드 조회
export const getRecordBySetId = async (setId: any) => {
  const response = await api.get(`/record/${setId}`);
  return response.data;
};

// 새로운 레코드 세트 생성
export const createRecordSet = async (id: any, setcategory: any, setname: any) => {
  const response = await api.post('/recordSet', { id, setcategory, setname });
  return response.data;
};

// records 테이블에 레코드 추가
export const createRecord = async (info1: any, info2: any, info3: any, name: any, id: any, result: any, setId: any) => {
  console.log(info1, info2, info3, name, id, result, setId)
  const response = await api.post('/records', { info1, info2, info3, name, id, result, setId });
  return response.data;
};

// 레코드 업데이트
export const updateRecord = async (id: any, setId: any, info1: any, info2: any, info3: any, result: any) => {
  const response = await api.put(`/updaterecord/${id}`, { setId, info1, info2, info3, result });
  return response.data;
};

// 레코드 삭제
export const deleteRecord = async (id: any) => {
  const response = await api.put(`/deleterecord/${id}`);
  console.log('delete', id)
  return response.data;
};



export default api;