import * as React from 'react';
import {
  Wrapper,
  MenuWrapper,
  MenuText,
  InputTitle,
  InputBlank,
  SolidButton,
  BorderButton,
  Img
} from '../../styles/signin.style';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { authStore } from '../../store';
import { login } from '../../api';
import { useHistory } from 'react-router-dom';

export interface ISigninProps { }

const Signin: React.FC<ISigninProps> = observer(() => {

  const [id, setId] = useState("");
  const [pw, setPw] = useState("");
  const history = useHistory();

  const onSignIn = async () => {
    try {
      const data = await login(id, pw);
      if (data.authentication) {
        authStore.logIn(data.user);
        localStorage.setItem('token', data.token); // JWT를 로컬 스토리지에 저장
        // console.log(data.user)
        history.push('/')
      } else {
        alert('로그인 정보가 일치하지 않습니다.');
      }
    } catch (error) {
      console.error('로그인 오류:', error);
      alert('로그인 중 오류가 발생했습니다.');
    }
  };

  const onChangeId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setId(e.target.value);
    // console.log(e.target.value);
  };

  const onChangePw = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPw(e.target.value);
    // console.log(e.target.value);
  };

  const onClickSignin = () => {
    onSignIn();
  };

  const onClickSignup = () => {
    history.push('/signup');
  };

  return (
    <Wrapper>
      <MenuWrapper>
        <a onClick={() => history.push('/')}>
          <Img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b66e0e117a095bc5c8db6a3d44506af1d7cfe124caf391ed4720dfd4bc07f0f2?"
          />
        </a>
        <MenuText>🔒 로그인</MenuText>
      </MenuWrapper>
      <InputTitle>아이디</InputTitle>
      <InputBlank type='text' value={id} onChange={onChangeId} />
      <InputTitle>비밀번호</InputTitle>
      <InputBlank type='password' value={pw} onChange={onChangePw} />
      <SolidButton onClick={onClickSignin}>로그인</SolidButton>
      <BorderButton onClick={onClickSignup}>회원가입</BorderButton>
    </Wrapper>
  );
});

export default Signin;