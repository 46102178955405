import styled from 'styled-components'
import bg from '../assets/image/bg.png'

export const HomeWrapper = styled.div`
  width: 100%;
  min-width: 1024px;
  margin: 105px auto 30px;

  display:flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: #4d4d4d;
  font-weight: 700;

  .ant-tabs-nav {
    position: relative;
    margin-top: 30px;
    border: none;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-tab {
    width: fit-content;
    margin: 0 20px;
    padding: 10px 15px;
    border-radius: 8px;
    border: 1px solid #ababab;
  }
  .ant-tabs-tab-btn {
    text-align: center;
    text-shadow: none;
    font-size: 18px;
    font-weight: 400;
    color: #a3a3ae;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    border: none;
    background-color:#FFD965;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
    font-weight: 700;
    text-shadow: none;
    border: none;
  }

`

export const ButtonWrapper = styled.div`

  width:100%;
  padding: 15px 43px;
  display: flex;
  justify-content:right;

  a {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 700;
    color: #535353;
    background-color:#ffffff;
    border-radius:15px;
    border : 3px solid #0F7B40;
    img {
      margin-right: 10px;
    }
  }
`
export const ActionTable = styled.table`
  margin : 0 125px 30px 125px;
  font-size: 16px;
  text-align: center;
  border-top: 1px solid #4d4d4d;
  border-bottom: 1px solid #4d4d4d;
  .description {
    background-color:#EFEFEF;
    button {
      width:90%;
      font-size:16px;
      background-color:#535353;
      padding : 5px 15px;
      border: none;
      border-radius: 5px;
      color: #ffffff;
    }
  }
  tr:first-child {
    th:first-child {
      width: 7% !important;
    }
    th:nth-child(2), th:nth-child(3) {
      width: 15% !important;
    }
    th {
      padding: 15px 0;
      width: 20%;
    }
    th:last-child {
      width: 7%;
    }
  }
  tbody tr {
    line-height: 2rem;
    border-collapse: separate;
    border-spacing: 0 1rem;
  }
  tbody td {
    padding:10px 0;
    font-size:16px;
  }
  tbody td input {
    padding : 5px 10px;
    width:90%;
    border-radius: 5px;
    border: 1px solid #ababab;
  }
  tbody td input::placeholder {
    color:#bcbcbc;
  }
`

export const MenuWrapper = styled.div`
  align-self: start;
  display: flex;
  margin : 0 100px;
  gap : 20px;
  justify-content: space-between;
`;

export const MenuText = styled.div`
  font-family: Pretendard, sans-serif;
  font-size: 18px;
`;
export const MenuDesc = styled.div`
  font-family: Pretendard, sans-serif;
  font-size: 14px;
  font-weight:400;
`;

export const Img = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
  margin-right:20px;
`;

export const ToolWrapper = styled.div`
  display: flex;
  width:100%;
  justify-content: space-between;
  align-items: center;
  margin : 10px 0;
  padding: 0 150px;
  font-size: 16px;
  text-align: center;
  p {
    font-size: 16px;
    margin : 0;
  }
`;

export const ToolSubWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items:center;
`;

export const SolidButton = styled.button`
  border : none;
  font-family: Pretendard, sans-serif;
  border-radius: 5px;
  background-color: #ffa842;
  width: 70px;
  max-width: 100%;
  align-items: center;
  color: #fff;
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  padding: 10px ;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

export const BorderButton = styled.button`
  color:#4d4d4d;
  font-family: Pretendard, sans-serif;
  border-radius: 5px;
  border-color: rgba(255, 191, 116, 1);
  border-style: solid;
  border-width: 2px;
  background-color: #fff;
  width: 70px;
  max-width: 100%;
  align-items: center;
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  padding: 10px ;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;