import * as React from 'react'
import Header from './layout/header'
import Footer from './layout/footer'
import axios from 'axios';

// 로컬 스토리지에서 토큰을 가져와 Axios에 설정
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

declare global {
  interface Window {
    kakao: any
    dataLayer: any
  }
}

export default class App extends React.Component {
  children: any

  public render() {
    return (
      <>
        <Header />
        {this.children}
        <Footer />
      </>
    )
  }
}
