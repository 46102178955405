import styled from 'styled-components'
import bg from '../assets/image/main_bg.png'

export const HomeWrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const MainView = styled.div`
  margin-top: 75px;
  padding : 180px 0 130px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-image: url(${bg});
  background-size: cover;
  min-height: 500px;
`

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  padding: 0 120px;
  @media (max-width: 414px) {
    padding: 25px 0;
  }
`

export const TitleTextPC = styled.text`
  width: 100%;
  color: #ffffff;
  font-size: 60px;
  font-weight: 700;
  text-align: left;
  line-height: 90px;

  @media (max-width: 414px) {
    display:none;
  }
`

export const TitleTextMobile = styled.text`
  width: 100%;
  color: #ffffff;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  line-height: 2.5rem;
  padding: 0 30px;

  @media (min-width: 414px) {
    display:none;
  }
`

export const TitleSub = styled.text`
  width: 100%;
  padding: 25px 0 107px;
  color: #ffffff;
  font-size: 20px;
  text-align: left;
  line-height: 28px;
  @media (max-width: 414px) {
    font-size: 18px;
    line-height: 22px;
  }
`

export const TryButton = styled.text`
  padding: 20px 55px;
  border-radius: 28px;
  background-color: #FFA842;
  color:#ffffff;
  font-size: 20px;
  text-decoration: none;
`

export const Spacer = styled.div`
  width:100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const CardViewWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 25px 0 75px;
`

export const CardViewTitle = styled.text`
  font-size: 40px;
  color: #FFA842;
  text-align: center;
  line-height: 28px;
  width:100%;
  font-weight: 700;
  margin: 25px 0;
`

export const CardView = styled.div`
  width: 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-width: 300px;
  margin: 25px 10px;
  padding: 35px 20px;
  background: #FFFFFF;
  border: 1px solid #DBDBDB;
  box-shadow: 2px 5px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  img {object-fit:contain;}
`

export const CardTitle = styled.text`
  font-size: 23px;
  color: #4D4D4D;
  text-align: center;
  line-height: 28px;
  width:100%;
  font-weight: 500;
  margin: 25px 0;
  word-break: keep-all;
`

export const CardSub = styled.text`
  font-size: 18px;
  color: #4D4D4D;
  text-align: center;
  line-height: 28px;
  width:100%;
  font-weight: 300;
  margin: 0 0 25px;
  word-break: keep-all;
`

export const PartnerImgWrapper = styled.div`
  width:100%;
  display:flex;
  justify-content: center;
  margin-top: 25px;
`

export const ParterImg = styled.img`
  margin: 0px 25px;
  object-fit: contain;
`

export const PartnerText = styled.text`
  margin-top: 100px;
  text-align:center;
  color: #4D4D4D;
  font-size: 30px;
  font-weight: 500;
`