import styled from 'styled-components'
import { Drawer } from 'antd'

export const HeaderWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;

  .pc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    padding: 0 150px;
    border-bottom: 1px solid #000;
    img {
      width: 123px;
    }
  }

  .mobileWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 25px 30px;

    @media (max-width: 414px) {
      padding: 17px 20px;
    }

    img {
      width: 110px;

      @media (max-width: 414px) {
        width: 80px;
      }
    }

    .menuBox {
      position: relative;
      width: 35px;
      height: 40px;

      .menuBar {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-60%);
        color: #fff;
        font-size: 18px;
        cursor: pointer;
        z-index: 100;
      }
    }
  }

  .scrollStyle {
    background: #fff;
    border-bottom: 1px solid #d9d9d9;

    a {
      color: #332e54 !important;
    }
    .menuBox {
      .menuBar {
        color: #332e54;
      }
    }
    .pcSubNav {
      background: rgba(255, 255, 255, 0.9) !important;
      border-bottom: 1px solid #d9d9d9;
    }
  }

  .hoverStyle {
    background: #fff;
    border-bottom: 1px solid #d9d9d9;

    a {
      color: #332e54 !important;
    }

    .pcSubNav {
      display: block;
    }
  }

  .clickStyle {
    .pcSubNav {
      display: none;
    }
  }
`
export const MobilePanel = styled(Drawer)`
  .ant-drawer-header {
    position: absolute;
    right: 0;
    padding-top: 18px;

    .ant-drawer-header-title {
      width: 25px;

      .ant-drawer-close {
        font-size: 25px;
        color: #222;
      }
    }
  }

  @media (max-width: 360px) {
    .ant-drawer-content-wrapper {
      width: 280px !important;
    }
  }
`
export const PcNavWrapper = styled.div`
  width:100%;
  height: 100%;

  .pcNav {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    div {
      display:flex;
      margin: 0 50px;
    }
    .menu {
      font-size:18px;
      margin : 0 25px;
    }
    a {
      display: block;
      padding: 10px 15px;
      text-align: center;
      font-size: 15px;
      font-weight: 600;
      color: #222222;
      &:hover {
        color: #641d87 !important;
      }
      img {
        width:20px;
        height:20px;
        margin-right:15px;
      }
    }
  }

  .pcSubNav {
    position: absolute;
    top: 85px;
    left: 0;
    width: 100%;
    height: 255px;
    background: rgba(255, 255, 255, 0.9);
    display: none;

    a {
      width: 95px;
      text-align: center;
      color: #332e54;
      white-space: nowrap;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`
export const MobileNavWrapper = styled.div`
  padding-top: 90px;

  .ant-collapse-header {
    position: relative;
    padding: 20px 20px !important;
    font-size: 18px;
    font-weight: 700;
    color: #313131;

    span {
      position: absolute;
      top: 22px;
      right: 20px;
      transform: rotate(90deg);
    }
  }

  .ant-collapse-item-active {
    span {
      transform: rotate(180deg);
    }
  }

  .ant-collapse-content-box {
    padding: 0 34px;

    a {
      display: block;
      padding: 25px 0;
      font-size: 16px;
      color: #707070;
    }
  }

  .ant-collapse-content-active {
    border-top: 2px solid #313131 !important;
  }
`
export const PcSubNavWrapper = styled.div`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: 50%;

  a {
    display: block;
    padding-top: 32px;
    color: #fff;
  }
`
export const FooterWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  background: #F9F9F9;
  color: #000;
  font-weight: 400;
  padding: 40px 0;

  .fWrap {
    display: flex;
    justify-content: space-between;
    padding: 0;
    flex-wrap: wrap;
    align-items: start;

    @media (max-width: 1240px) {
      padding: 0 30px;
    }
    @media (max-width: 1024px) {
      flex-direction: column;
    }
    @media (max-width: 414px) {
      padding: 0 20px;
    }

    img {
      width: 10%;
      margin-right: 25px;
      object-fit: contain;
      vertical-align:top;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      width: 85%;
      margin-bottom: 0;

      @media (max-width: 820px) {
        width: auto;
      }

      li {
        width: 100%;
        padding: 0 20px 0 0;
        font-size: 13px;
        line-height: 25px;
      }
    }

    p {
      margin: 34px 0 0;
      font-size: 14px;

      @media (max-width: 1024px) {
        position: absolute;
        bottom: 36px;
        margin: 0;
      }
    }

    .sns {
      position: relative;
      top: 8px;
      @media (max-width: 1024px) {
        top: 0;
        margin: 28px 0;
      }

      a {
        margin-right: 25px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
`
