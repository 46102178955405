import * as React from 'react'
import { Link, Route } from 'react-router-dom'
import {
  HomeWrapper,
  MainView,
  TitleWrapper,
  TitleTextPC,
  TitleTextMobile,
  TitleSub,
  TryButton,
  CardViewWrapper,
  CardView,
  CardViewTitle,
  Spacer,
  CardTitle,
  CardSub,
  ParterImg,
  PartnerImgWrapper,
  PartnerText,
} from '../../styles/home.style'
import downscroll from '../../assets/image/downscroll_icon.png'
import card_img_1 from '../../assets/image/card_img_1.png'
import card_img_2 from '../../assets/image/card_img_2.png'
import card_img_3 from '../../assets/image/card_img_3.png'
import partner_1 from '../../assets/image/partner_1.png'
import partner_2 from '../../assets/image/partner_2.png'
import partner_3 from '../../assets/image/partner_3.png'


export interface IHomeProps {}

export default class Home extends React.Component<IHomeProps> {
  public render() {
    return (
      <HomeWrapper>
        <MainView>
          <TitleWrapper>
            <TitleTextPC>AI 생활기록부 생성<br></br>
              advAIsor</TitleTextPC>
            <TitleSub>한 번의 클릭으로 생활기록부를 각 학생의 특성에 맞게 생성해보세요.</TitleSub>
            <Link to={'/beta?tab=action'}><TryButton> 체험해보기</TryButton></Link>
          </TitleWrapper>
        </MainView>

        <Spacer>
          <img src={downscroll} />
        </Spacer>
        
        <CardViewWrapper>
          <CardViewTitle>advAIsor 소개</CardViewTitle>
          <CardView>
            <img src={card_img_1} />
            <CardTitle>빠르고 정확한 생성 자동화 서비스</CardTitle>
            <CardSub>생활기록부 생성 및 관리 방식을 새롭게 제안하기 위해 고안된 혁신적인 서비스 advAIsor</CardSub>
          </CardView>
          <CardView>
            <img src={card_img_2} />
            <CardTitle>키워드 기반 개인화된 내용 생성</CardTitle>
            <CardSub>학생의 특징적인 키워드를 입력하기만 하면 키워드를 분석하여 상세하고 개인화된 생활기록부 내용을 자동으로 생성합니다.</CardSub>
          </CardView>
          <CardView>
            <img src={card_img_3} />
            <CardTitle>교사 친화적인 인터페이스</CardTitle>
            <CardSub>선생님은 글 작성에 소요되는 시간을 줄이고, 중요한 일. 즉, 학생을 가르치는 데에 더 많은 시간을 할애할 수 있습니다.</CardSub>
          </CardView>
        </CardViewWrapper>

        <CardViewWrapper>
          <CardViewTitle>파트너사</CardViewTitle>
          <PartnerImgWrapper>
            <ParterImg src={partner_1} />
            <ParterImg src={partner_2} />
            <ParterImg src={partner_3} />
          </PartnerImgWrapper>
          <PartnerText>맞춤형 생활기록부 작성 서비스인 advAIsor와<br></br>학생들의 미래 설계를 함께하세요</PartnerText>
        </CardViewWrapper>
      </HomeWrapper>
    )
  }
}
