import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import ButtonTheme from '../../components/buttonTheme';
import {
  Wrapper,
  MenuWrapper,
  MenuText,
  InputTitle,
  InputBlank,
  SolidButton,
  BorderButton,
  Img,
} from '../../styles/signup.style';
import { signup, checkUsername, createRecordSet } from '../../api';

const Signup: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [isUsernameAvailable, setIsUsernameAvailable] = useState<boolean | null>(null);

  const history = useHistory();

  const handleSignup = async () => {
    if (password !== confirmPassword) {
      alert('비밀번호가 일치하지 않습니다.');
      return;
    }

    if (isUsernameAvailable === false) {
      alert('이미 사용중인 ID 입니다.');
      return;
    }

    try {
      const data = await signup(username, password, email, role);
      
      if (data.success) {
        alert('회원가입이 완료되었습니다. 로그인 해주세요.');
        history.push('/signin'); // 회원가입 후 메인 화면으로 이동
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error('Signup error:', error);
      alert('회원가입 오류 - 관리자에 문의하세요.');
    }
  };

  const handleCheckUsername = async () => {
    try {
      const result = await checkUsername(username);
      setIsUsernameAvailable(result.success);
      if (result.success) {
        alert('사용가능한 ID 입니다.');
      } else {
        alert('이미 사용중인 ID 입니다.');
      }
    } catch (error) {
      console.error('Check username error:', error);
      alert('ID 중복검사 오류 - 관리자에 문의하세요.');
    }
  };

  return (
    <Wrapper>
      <MenuWrapper>
        <a onClick={() => history.push('/')}>
          <Img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b66e0e117a095bc5c8db6a3d44506af1d7cfe124caf391ed4720dfd4bc07f0f2?"
            />
        </a>
        <MenuText>👋 회원가입</MenuText>
      </MenuWrapper>
      <InputTitle>아이디</InputTitle>
      <InputBlank type='text' value={username} onChange={e => setUsername(e.target.value)} />
      <BorderButton onClick={handleCheckUsername}>ID 중복 확인</BorderButton>
      <InputTitle>비밀번호</InputTitle>
      <InputBlank type='password' value={password} onChange={e => setPassword(e.target.value)} />
      <InputTitle>비밀번호 확인</InputTitle>
      <InputBlank type='password' value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
      <InputTitle>이메일</InputTitle>
      <InputBlank type='email' value={email} onChange={e => setEmail(e.target.value)} />
      <InputTitle>소속</InputTitle>
      <InputBlank type='text' value={role} onChange={e => setRole(e.target.value)} />
      <SolidButton onClick={handleSignup}>가입완료</SolidButton>
    </Wrapper>
  );
};

export default Signup;
